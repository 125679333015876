import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I know, accessibility can be daunting. Especially when you take your first look at the abundance
of `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/quickref/"
      }}>{`WCAG criteria`}</a>{` and
resources available. My advice: Take a deep breath, pick a topic you're interested in (e.g. semantic markup) and
improve one thing in your website or app. Accessibility isn't an "all or nothing" issue! Every single improvement counts.`}</p>
    <h2>{`Where to begin?`}</h2>
    <p>{`Here's a list of first improvements you can make to your website or app:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Check the color contrast of text, graphical elements and UI components.`}</strong>{` High color contrast especially helps older
people who are `}<a parentName="p" {...{
            "href": "https://eldertech.org/color-in-designing-technology-for-seniors/"
          }}>{`less able to make out low contrast patterns`}</a>{`.
Related WCAG criteria: `}<a parentName="p" {...{
            "href": "https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html"
          }}>{`1.4.3 Contrast (Minimum)`}</a>{` and
`}<a parentName="p" {...{
            "href": "https://www.w3.org/WAI/WCAG21/Understanding/non-text-contrast.html"
          }}>{`1.4.11: Non-text Contrast`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Interact with your website only using a keyboard.`}</strong>{` Can you navigate to all interactive elements via the tab key? If not,
check if the elements are implemented with the correct semantic markup (e.g. button and link tags). On your smartphone,
`}<strong parentName="p">{`use the screen reader to navigate`}</strong>{` via swiping and check if interactive elements are correctly identified and can be activated.
Related WCAG criteria: `}<a parentName="p" {...{
            "href": "https://www.w3.org/WAI/WCAG21/Understanding/keyboard.html"
          }}>{`2.1.1: Keyboard`}</a>{` and
`}<a parentName="p" {...{
            "href": "https://www.w3.org/WAI/WCAG21/Understanding/name-role-value.html"
          }}>{`4.1.2: Name, Role, Value`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Do your videos offer captions?`}</strong>{` Captions enable people who are deaf or hard of hearing to watch videos.
At the same time, everyone benefits who finds themselves in a situation where they can't listen to the audio
(e.g. in a crowded subway with no headphones). Related WCAG criteria:
`}<a parentName="p" {...{
            "href": "https://www.w3.org/WAI/WCAG21/Understanding/captions-prerecorded.html"
          }}>{`1.2.2: Captions (Prerecorded)`}</a></p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      